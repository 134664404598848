/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { ArtistsList, Layout, PageHeading, ScrollLayout } from "components";

function filterArtists(artists) {
  return artists
    .map(({ node }) => node)
    .filter(
      ({ artworks, force_display_artist = false }) =>
        artworks.filter(({ is_archived }) => !is_archived).length > 0 ||
        force_display_artist
    );
}

function ArtistsPage({
  data: { modernBritishArtists, internationalAndCompteporaryArtists },
}) {
  const sections = [
    {
      id: "modern-british",
      title: "Modern British",
      children: (
        <ArtistsList
          key="modern-british"
          id="modern-british"
          heading="Modern British"
          artists={filterArtists(modernBritishArtists.artists)}
        />
      ),
    },
    {
      id: "international-and-contemporary",
      title: "International & Contemporary",
      children: (
        <ArtistsList
          key="international-and-contemporary"
          id="international-and-contemporary"
          heading="International & Contemporary"
          artists={filterArtists(internationalAndCompteporaryArtists.artists)}
        />
      ),
    },
  ];

  return (
    <Layout pageTitle="Artists">
      <PageHeading heading="Artists" />
      <ScrollLayout sections={sections} sx={{ pb: "240px" }} />
    </Layout>
  );
}

export const query = graphql`
  {
    modernBritishArtists: allSanityArtist(
      filter: {
        categories: { elemMatch: { category: { eq: "Modern British" } } }
        create_web_page: { eq: true }
      }
      sort: { fields: last_name, order: ASC }
    ) {
      artists: edges {
        node {
          _id
          last_name
          first_name
          force_display_artist
          artworks {
            _id
            is_archived
          }
        }
      }
    }

    internationalAndCompteporaryArtists: allSanityArtist(
      filter: {
        categories: { elemMatch: { category: { ne: "Modern British" } } }
        create_web_page: { eq: true }
      }
      sort: { fields: last_name, order: ASC }
    ) {
      artists: edges {
        node {
          _id
          last_name
          first_name
          force_display_artist
          artworks {
            _id
            is_archived
          }
        }
      }
    }
  }
`;

export default ArtistsPage;
